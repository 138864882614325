<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Experience</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2021 - Present</span>
            <h3 class="title">Berlin School of business and innovation (Bachelor’s Degree)</h3>
            <p>I am currently pursuing a Bachelor's degree in Computer Science and Digitization, where I am learning advanced technical skills such as machine learning, data analysis, modeling, and programming language usage. These skills will enable me to provide efficient solutions to complex problems.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2016</span>
            <h3 class="title">Intership at Sorooshpardaz.com</h3>
            <p>As a self-taught iOS developer, I have gained valuable experience through an internship with a startup company, where I learned firsthand about the challenges and rewards of real-world iOS development and startup functioning. This experience has greatly impacted my skills and understanding of the industry.</p>
          </div>
        </div>

  

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden" >
        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp" *ngIf="showDiv">
          
        </div> -->

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2020 Present</span>
            <h3 class="title">IOS Developer at Observer.com.tr</h3>
            <p>Developing IOS apps for fire alarms, and Real Estate Services.
              Reviewed client requirements, wireframes, and designs for technical feasibility. Collaborated with the scrum team to translate client requirements into implementable user stories.
              Improved product quality through code reviews, writing effective unit tests and collaborating with QA on implementing automation testing.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2018 - 2020</span>
            <h3 class="title">Mobile Developer at Talyatasarim.com</h3>
            <p>Worked closely with product managers and designers to define a rich iOS experience for the user.
              Supported the architect in improving the native iOS codebase.</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
