<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Me</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img [ngClass]="{'profile-image-about-me': true, 'mb-4': true}" src="assets/images/pro.JPG" alt="Profile Image"/>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p #introParagraph>
              Hello! My name is Alireza and I have been a self-taught iOS developer for the past 5 years. I am proficient in technologies such as Swift, Xcode, and the iOS SDK. In addition to my experience in iOS development, I have also recently focused on learning and mastering technologies such as Angular JS, TypeScript, C#, and Azure IoT.
            </p>
            <div class="mt-3">
              <a href="javascript:" class="btn btn-default">Download CV</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Swift</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="primary" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Java</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="primary" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Html CSS</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="primary" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">SQL</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="primary" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Javascript</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="primary" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">UI/UX design</h4>
                <span class="float-right">50%</span>
              </div>
              <ngb-progressbar type="primary" [value]="50"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Angular JS</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">CI/CD principles</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

  <div class="row">
    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">40</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">5670</em></h3>
          <p class="mb-0">Cup of coffee</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">15</em></h3>
          <p class="mb-0">Satisfied clients</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-badge"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">20</em></h3>
          <p class="mb-0">Nominees winner</p>
        </div>
      </div>
    </div>
  </div>
</div>
