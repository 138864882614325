import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import { ServicesService } from './ServicesService';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

   FormData: FormGroup;
  // constructor(private contact: ServicesService) { }
  constructor() { }
    ngOnInit() {}
  
  
    // onSubmit(FormData) {
      // console.log(FormData)
      // this.contact.PostMessage(FormData)
        // .subscribe(response => {
          // location.href = 'https://mailthis.to/confirm'
          // console.log(response)
        // }, error => {
          // console.warn(error.responseText)
          // console.log({ error })
        // })
    // }
  
}
