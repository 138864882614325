import { Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('introParagraph', { static: true }) introParagraph: ElementRef;
  showDiv: boolean;
  country:string;
  options = {
    method: 'GET',
    url: 'https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation',
    params: new HttpParams().set('apikey', '873dbe322aea47f89dcf729dcc8f60e8'),
    headers: new HttpHeaders()
      .set('X-RapidAPI-Key', '54e40a81a7msh9f79fabdd6f7292p1c5bf5jsn058efcdc0af6')
      .set('X-RapidAPI-Host', 'find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com')
  };
  constructor(private http: HttpClient) {   
    this.http.get(this.options.url, { params: this.options.params, headers: this.options.headers }).subscribe((response) => {
      if (response['countryISO2'] === 'TR') {
        this.introParagraph.nativeElement.textContent = 'As a self-taught iOS engineer with over five years of experience, I have a strong track record of leading iOS development for large startups and taking on independent contracting work. Throughout my career, I have gained extensive experience in leading iOS development for companies such as Observer.com.tr and Talyatasarim.com. In addition to my work with these large startups, I have also had the opportunity to work on a variety of independent contracting projects, which have allowed me to gain valuable knowledge and experience in a variety of different contexts. My self-taught background has given me the ability to learn quickly and adapt to new situations, and I am confident in my ability to take on new challenges and deliver high-quality results. I am excited to continue growing and learning as an iOS engineer, and am eager to take on new opportunities to use my skills and expertise to create successful projects.';
      } else {
      }
    });   
  }

  ngOnInit(): void {}
}
