<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Services</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-blue bg-blue">
        <img src="assets/images/service-1.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">UI/UX design</h3>
        <p class="mb-0">My experience working with talented people has allowed me to gain a deep understanding of how a project's appearance can impact its users. I take great care to ensure that the UI and UX of my projects are both visually appealing and intuitive, which helps to create a positive experience for customers.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
        <img src="assets/images/service-2.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">Web Development</h3>
        <p class="mb-0 text-wrap">I specialize in web development using the latest technologies and the CI/CD process to deliver efficient, cutting-edge solutions. My expertise and experience allow me to create high-quality products that meet my clients' needs and drive business growth. Custom web development solutions for success</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
        <img src="assets/images/service-2.svg" alt="UI/UX design" />
        <h3 class="mb-3 mt-0">App Development</h3>
        <p class="mb-0">As a specialist in native iOS and Android app development, I deliver exceptional products with a seamless user experience and enhanced efficiency. My expertise allows me to create top-quality apps that go above and beyond my clients' expectations. Let me help you succeed with a new app.</p>
      </div>
    </div>
  </div>

  <div class="mt-5 text-center">
    <p class="mb-0">Looking for a custom job? <a href="javascript:" (click)="scrollTo('contact')">Click here</a> to contact me! 👋</p>
  </div>
</div>
