import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})


export class ExperienceComponent implements OnInit {
  showDiv: boolean;
  country:string;
  options = {
    method: 'GET',
    url: 'https://find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com/iplocation',
    params: new HttpParams().set('apikey', '873dbe322aea47f89dcf729dcc8f60e8'),
    headers: new HttpHeaders()
      .set('X-RapidAPI-Key', '54e40a81a7msh9f79fabdd6f7292p1c5bf5jsn058efcdc0af6')
      .set('X-RapidAPI-Host', 'find-any-ip-address-or-domain-location-world-wide.p.rapidapi.com')
  };
  constructor(private http: HttpClient) {   
    this.http.get(this.options.url, { params: this.options.params, headers: this.options.headers }).subscribe((response) => {
      if (response['countryISO2'] === 'TR') {
        // If the user is from Turkey, hide the div
        this.showDiv = false;
      } else {
        // If the user is not from Turkey, show the div
        this.showDiv = true;
      }
    });   
  }

  ngOnInit() {}
}
